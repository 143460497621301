import router from "../../router/index";

let user = localStorage.getItem("authUser");

export const state = {
  currentUser:
    localStorage.getItem("authUser") == "undefined"
      ? null
      : JSON.parse(localStorage.getItem("authUser")),
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
    saveState("authUser", newValue);
  },
};

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser;
  },
  currentUser(state) {
    return state.currentUser;
  },
};

export const actions = {
  logIn({ dispatch, commit, getters }, { email, password }) {
    // if (getters.loggedIn) return dispatch('validate')
    //commit('loginRequest', { email });
    let loginData = {
      email: email,
      password: password,
    };

    return new Promise((resolve, reject) => {
      this._vm.$axios
        .post("login", loginData)
        .then((response) => {
          console.log(response);
          if (response.data.status == true) {
            const user = response.data.user;
            commit("SET_CURRENT_USER", user);
            window.location.href = "/";
          }
          resolve(response);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          // dispatch('notification/error', error, { root: true });
        });
    });
  },

  // Logs out the current user.
  logOut({ commit }) {
    this._vm.$axios.post("logout").then((response) => {
      commit("SET_CURRENT_USER", null);
      // router.push('/login');
      location.href = "/login";
    });
  },

  me({ commit }) {
    if (state.currentUser != null) {
      this._vm.$axios
        .get("/me")
        .then((response) => {
          const user = response.data.user;
          commit("SET_CURRENT_USER", user);
        })
        .catch((error) => {
          commit("SET_CURRENT_USER", null);
          location.href = "/login";
        });
    }
  },
};

// ===
// Private helpers
// ===

function saveState(key, state) {
  if (state == null) {
    // console.log('nul',state)
    window.localStorage.setItem(key, null);
  } else {
    // console.log(state)

    window.localStorage.setItem(key, JSON.stringify(state));
  }
}
