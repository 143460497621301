import Vue from "vue";
import VueRouter from "vue-router";

import VueMeta from "vue-meta";
// import store from '@/state/store'

import routes from "./routes";
import { Role } from "@/state/helpers";

Vue.use(VueRouter);
Vue.use(VueMeta, {
  // The component option name that vue-meta looks for meta info on.
  keyName: "page",
});

const router = new VueRouter({
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  const publicPages = ["/login","/forgot-password","/reset-password"];
  const authpage = !publicPages.includes(routeTo.path);
  const loggeduser = localStorage.getItem("authUser");
  const { authorize, authorize_manager, authorize_team_member } = routeTo.meta;

  if (authpage && (!loggeduser || loggeduser == "null")) {
    return next("/login");
  }
  if (loggeduser && loggeduser != "null") {
    let user = JSON.parse(loggeduser);
    if (
      user.type == authorize ||
      user.type == authorize_manager ||
      user.type == authorize_team_member
    ) {
      next();
    } else {
      return next("/login");
    }
  }

  next();
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

export default router;
