import Vue from "vue";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import BootstrapVue from "bootstrap-vue";
import router from "./router";

import vco from "v-click-outside";
import i18n from "./i18n";

import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";

import VueSlideBar from "vue-slide-bar";
import Vuelidate from "vuelidate";
import store from "@/state/store";
import axios from "axios";
import SubmitButton from "./components/general/SubmitButton";
import APILoader from "./components/general/APILoader";

// Vue.config.devtools = true;
import App from "./App.vue";
// As a plugin
Vue.config.productionTip = false;

// import mixins
import GlobalMixin from "./mixins/global";

if (store.getters["auth/loggedIn"]) {
  Vue.mixin(GlobalMixin);
}

import "@/assets/scss/app.scss";

Vue.component("VueSlideBar", VueSlideBar);
Vue.component("SubmitButton", SubmitButton);
Vue.component("APILoader", APILoader);
Vue.component("apexchart", VueApexCharts);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(vco);

Vue.use(VueSweetalert2);

import SawalMixin from "./mixins/swalToasterMixin";
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)
Vue.mixin(SawalMixin);

const Base_Url = process.env.VUE_APP_BASEURL;
// const Base_Url = "https://tipsharebusiness.pixarsclients.com/api/";
// const Base_Url = "https://tipsharebusiness.pixarsclients.com/api/";
// const Base_Url = "http://127.0.0.1:8000/api/";
const token = JSON.parse(localStorage.getItem("token"));
var user =
  localStorage.getItem("authUser") == "undefined"
    ? null
    : JSON.parse(localStorage.getItem("authUser"));

//if(user!=null){

Vue.prototype.$axios = axios.create({
  baseURL: Base_Url,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: "Bearer " + user?.token,
    // "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    // "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
  },
});
// this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

import PortalVue from "portal-vue";
Vue.use(PortalVue);
