const userObj = {
    email: null,
    type: null,
    name: null,
    password:null,
    phone:null,
    avatar:null,
    restaurant:{
        name:null,
        country:null,
        logo:null
    },
    locations:[]
}
export const state = {
        user: JSON.parse(JSON.stringify(userObj)),
        //   ...userObj
    }
   
    export const actions = {
        getuser({commit}){
            this._vm.$axios.get('/me')
            .then(response => {
                let user = response.data.payload.user;
                   commit('updateUser',user)              
            });
            
        },

        resetState({commit}) {
            commit('updateUser',JSON.parse(JSON.stringify(userObj)));
         },
    }

  export const mutations= {
        updateUser(state, new_val) {
            state.user = new_val
        },
    }

    // export const getters= {
    //     user(state) {
    //         return state.user;
    //     }
    // }
    export const getters = {
        userGetter: state => state.user
      };




